// import React, { FC, Fragment } from 'react';
// import {
//     CampaignPageFragment,
//     CampaignPageFragment_solutionEntry_Craft_Solution,
//     CTAFragment,
// } from '../../entities/operationResults';
// import Meta from '../atoms/Meta';
// import CmsContent from '../atoms/CmsContent';
import Title from '../atoms/Title';
// import CTA from '../molecules/CTA';
// import CampaignCases from '../organisms/CampaignCases';
// import Features from '../organisms/Features';
// import VerticalContactForm from '../organisms/VerticalContactForm';
import styled from 'styled-components';
import theme from '../../constants/theme';
// import BodyText from '../atoms/BodyText';
// import Img from '../atoms/Img';

// interface Props {
//     entry: CampaignPageFragment;
// }

// const Markteting2019: FC<Props> = ({ entry }) => {
//     const title = entry.title;
//     const intro = entry.intro && entry.intro[0];
//     const subtitle = intro && intro.text && intro.text.content;
//     const description = intro && intro.subText && intro.subText.content;
//     const solutionEntry = entry.solutionEntry && entry.solutionEntry[0];
//     const solution = solutionEntry as CampaignPageFragment_solutionEntry_Craft_Solution;
//     const solutionHero = solution.hero && solution.hero[0];
//     const illustration = solutionHero && solutionHero.illustration;
//     const features = solution && solution.features;
//     const featuresTitle = solution && solution.featuresTitle;
//     const clients = solution && solution.cases;
//     const ctaList = (entry.callToAction || []) as CTAFragment[];
//     return (
//         <Fragment>
//             <Meta title={title && title} />
//             <Container>
//                 {title && <PageTitle variant="huge">{title}</PageTitle>}
//                 {subtitle && <CmsContent html={subtitle} paragraphWrapper={Title} />}
//                 <Row>
//                     <ColA>
//                         {description && <Copy as="div">{description && <CmsContent html={description} />}</Copy>}
//                         {ctaList && ctaList[0] && <CTA variant="hero" data={ctaList[0]} isVertical />}
//                     </ColA>
//                     <ColB>
//                         {illustration && (
//                             <Image src={illustration || undefined} alt={title || undefined} ratio={1369 / 2237} />
//                         )}
//                     </ColB>
//                 </Row>
//                 {clients && <CampaignCases title={entry.campaignClientsHeader} data={clients} />}
//             </Container>
//             {features && <Features data={features} isVertical title={featuresTitle} />}
//             <VerticalContactForm subject={`Campaign ${title}`} />
//         </Fragment>
//     );
// };

// export default Markteting2019;

// const Container = styled.div`
//     padding: 10rem;
//     box-sizing: border-box;
//     max-width: 170rem;
//     margin: auto;
//     @media (max-width: ${theme.mediaQueries.width.s}) {
//         padding: 2rem;
//     }
//     @media (${theme.mediaQueries.vertical}) {
//         width: 100vw;
//     }
// `;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 4rem;
`;

export const ColA = styled.div`
    flex: 2;
    flex-direction: column;
    max-width: 85rem;
`;

export const ColB = styled.div`
    position: relative;
    flex: 2;
    max-width: 70%;
    @media (max-width: ${theme.mediaQueries.width.m}) {
        display: none;
    }
`;

export const PageTitle = styled(Title)`
    margin-bottom: 4rem;

    @media (${theme.mediaQueries.horizontal}) {
        margin-top: 2rem;
    }
`;

// const Copy = styled(BodyText)`
//     max-width: 75rem;
// `;

// const Image = styled(Img)`
//     max-width: 75rem;
//     width: 100%;
//     margin-top: 10rem;
// `;
