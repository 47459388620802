import React from 'react';
import { CampaignPageFragment } from '../../entities/operationResults';
import MarketingOerolDemo from './MarketingOerolDemo';
import MarketingOerolWhitepaper from './MarketingOerolWhitepaper';
import MarketingMicroservicesDemo from './MarketingMicroservicesDemo';
import MarketingMicroservicesWhitepaper from './MarketingMicroservicesWhitepaper';
import MarketingOnlineHetNieuweNormaal from './MarketingOnlineHetNieuweNormaal';

export default (entry: CampaignPageFragment) => {
    switch (entry.slug) {
        case 'digitale-festivals':
            return <MarketingOerolDemo entry={entry} />;
        case 'oerol-whitepaper':
            return <MarketingOerolWhitepaper entry={entry} />;
        case 'microservices-demo':
            return <MarketingMicroservicesDemo entry={entry} />;
        case 'microservices-whitepaper':
            return <MarketingMicroservicesWhitepaper entry={entry} />;
        case 'online-het-nieuwe-normaal':
            return <MarketingOnlineHetNieuweNormaal entry={entry} />;
        default:
            return null;
    }
};
