import { FC } from 'react';
import { graphql } from 'gatsby';
import withDefaultTransition from '../hocs/withDefaultTransition';
import mapCampaignPages from './marketing/mapCampaignPages';
import { CampaignPageFragment, CampaignPageQuery } from '../entities/operationResults';

interface Props {
    data: CampaignPageQuery;
}

export const query = graphql`
    query CampaignPageQuery($site: String, $uri: String) {
        craft {
            entry(site: $site, uri: $uri) {
                ...CampaignPageFragment
            }
        }
    }
`;

export const fragment = graphql`
    fragment CampaignPageFragment on Craft_Campaign {
        id
        slug
        title
        campaignClientsHeader
        intro {
            ...IntroFragment
        }
        ctaEntry {
            ...CTAFragment
        }
        campaignClientsHeader
        campaignClients {
            ... on Craft_Client {
                id
                clientLogo {
                    url
                }
                title
            }
        }
        campaignFactsHeader
        campaignFacts {
            fact
        }
        campaignPayoff
        campaignBenefitsHeader
        campaignBenefits {
            header
            description {
                content
            }
        }
        campaignCtaForm {
            ...CTAFragment
        }
    }
`;

const Campaign: FC<Props> = ({ data }) => {
    const entry = data.craft.entry as CampaignPageFragment;
    const customCampaignPage = mapCampaignPages(entry);
    return customCampaignPage;
};

export default withDefaultTransition(Campaign);
