import React, { FC } from 'react';
import { CTAFragment } from '../entities/operationResults';
import Meta from './atoms/Meta';
import { ColA, ColB, Row } from './marketing/Marketing2019';
import CmsContent from './atoms/CmsContent';
import Title from './atoms/Title';
import CTA from './molecules/CTA';
import Image, { ImageProps } from './ds/content/Image';
import styled from 'styled-components';
import theme from '../constants/theme';
import Quote from './molecules/Quote';
import { Body, H1, H2, H3 } from './ds/primitive/Typography';

interface Props {
    id: number;
    title: string;
    subtitle: string;
    description: string;
    imageProps?: ImageProps;
    clientTitle?: string;
    clientLogos?: ImageProps[];
    factsAndFiguresProps?: FactsAndFiguresProps;
    quote?: string;
    oberonFactsProps?: OberonFactsProps;
    ctaTop: CTAFragment;
    ctaForm: CTAFragment;
}

const Marketing: FC<Props> = ({
    id,
    title,
    subtitle,
    description,
    imageProps,
    clientTitle,
    clientLogos,
    factsAndFiguresProps,
    quote,
    oberonFactsProps,
    ctaTop,
    ctaForm,
}) => {
    return (
        <>
            <Meta title={title} description={subtitle} image={[{ url2000: imageProps?.src || null }]} />
            <Container>
                <CampaignHero
                    sourceId={id}
                    title={title}
                    subtitle={subtitle}
                    description={description}
                    cta={ctaTop}
                    imageProps={imageProps}
                    exampleClientsProps={
                        clientLogos && {
                            title: clientTitle,
                            exampleClients: clientLogos,
                        }
                    }
                />
            </Container>
            <SectionGray>
                <Container>
                    {factsAndFiguresProps && <FactsAndFigures {...factsAndFiguresProps} />}
                    {quote && <OberonQuote>{quote}</OberonQuote>}
                </Container>
            </SectionGray>
            <SectionDark>
                <Container>{oberonFactsProps && <OberonFacts {...oberonFactsProps} />}</Container>
            </SectionDark>
            {ctaForm && <CTA sourceId={id} variant="hero" data={ctaForm} open noMessage />}
        </>
    );
};

const CampaignHero: FC<{
    sourceId: number;
    title: string;
    subtitle?: string | null;
    description?: string | null;
    imageProps?: ImageProps;
    cta?: CTAFragment;
    exampleClientsProps?: ExampleClientsProps;
}> = ({ sourceId, title, subtitle, description, imageProps, cta, exampleClientsProps }) => {
    return (
        <div>
            <MainTitle variant="huge">{title}</MainTitle>
            {subtitle && <CmsContent html={subtitle} paragraphWrapper={Title} />}
            <Row>
                <ColA>
                    {description && <CmsContent html={description} />}
                    {cta && <CTA sourceId={sourceId} variant="hero" data={cta} isVertical />}
                    {exampleClientsProps && <ExampleClients {...exampleClientsProps} />}
                </ColA>
                <ColB>{imageProps && <Image {...imageProps} vertical={false} />}</ColB>
            </Row>
        </div>
    );
};

interface ExampleClientsProps {
    title?: string | null;
    exampleClients: ImageProps[];
}

const ExampleClients: FC<ExampleClientsProps> = ({ title, exampleClients }) => (
    <div>
        {title && <H3 variant="small">{title}</H3>}
        <LogoRow>
            {exampleClients.map((client, i) => (
                <Image key={i} {...client} />
            ))}
        </LogoRow>
    </div>
);

interface FactsAndFiguresProps {
    title?: string;
    factsAndFigures?: string[];
}

const FactsAndFigures: FC<FactsAndFiguresProps> = ({ title, factsAndFigures }) => (
    <div>
        {title && <H2 variant="regular">{title}</H2>}
        {factsAndFigures && factsAndFigures.length > 0 && (
            <FactContainer>
                {factsAndFigures?.map((factOrFigure, i) => (
                    <Fact key={i} stroke>
                        <Body variant="huge">{factOrFigure}</Body>
                    </Fact>
                ))}
            </FactContainer>
        )}
    </div>
);

const OberonQuote: FC = ({ children }) => (
    <QuoteContainer>
        <Quote variant="large">{children}</Quote>
    </QuoteContainer>
);

interface OberonFact {
    title: string;
    description: string;
}

interface OberonFactsProps {
    title?: string;
    facts?: OberonFact[];
}

const OberonFacts: FC<OberonFactsProps> = ({ title, facts }) => (
    <div>
        {title && <H2 variant="regular">{title}</H2>}
        {facts && facts.length > 0 && (
            <FactContainer>
                {facts.map((fact, i) => (
                    <Fact key={i}>
                        <H3 variant="small">{fact.title}</H3>
                        <Body as="div" variant="regular" dangerouslySetInnerHTML={{ __html: fact.description }} />
                    </Fact>
                ))}
            </FactContainer>
        )}
    </div>
);

const MainTitle = styled(H1)`
    margin-bottom: 0.5em;
`;

const Container = styled.div`
    padding: 10rem;
    box-sizing: border-box;
    max-width: 170rem;
    margin: auto;
    @media (max-width: ${theme.mediaQueries.width.s}) {
        padding: 2rem;
    }
    @media (${theme.mediaQueries.vertical}) {
        width: 100vw;
    }

    & > * + * {
        margin-top: 4rem;
    }
`;

const SectionGray = styled.section`
    background: ${theme.colors.alablaster};
`;

const SectionDark = styled.section`
    color: white;
    background: ${theme.colors.shark};
`;

const LogoRow = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 2.4rem;

    & > * {
        height: 6.8rem;
    }

    & > * + * {
        margin-left: 1.6rem;
    }
`;

const QuoteContainer = styled.div`
    text-align: center;
`;

const FactContainer = styled.div`
    margin-top: 4rem;
`;

const Fact = styled.div<{ stroke?: boolean }>`
    padding: 3rem 0;
    ${({ stroke }) => stroke && `border-top: 1px solid rgba(0,0,0,0.1);`}

    &:last-child {
        ${({ stroke }) => stroke && `border-bottom: 1px solid rgba(0,0,0,0.1);`}
    }
`;

export default Marketing;
