import React, { FC } from 'react';
import Marketing from '../Marketing';
import { CampaignPageFragment, CTAFragment } from '../../entities/operationResults';

interface Props {
    entry: CampaignPageFragment;
}

const MarketingMicroservicesWhitepaper: FC<Props> = ({ entry }) => (
    <Marketing
        id={entry.id}
        title={entry.title}
        subtitle={entry.intro?.[0]?.text?.content || ''}
        description={entry.intro?.[0]?.subText?.content || ''}
        ctaTop={((entry.ctaEntry || []) as CTAFragment[])?.[0]}
        imageProps={{
            src: require('../../images/marketing/microservices.png'),
            alt: 'Microservices Illustratie',
            ratio: 1,
        }}
        clientTitle={entry.campaignClientsHeader !== null ? entry.campaignClientsHeader : undefined}
        clientLogos={[{ src: require('../../images/marketing/logo-raleigh.svg'), ratio: 132 / 68 }]}
        factsAndFiguresProps={{
            title: entry.campaignFactsHeader !== null ? entry.campaignFactsHeader : '',
            factsAndFigures: (entry.campaignFacts
                ?.filter((f) => (f !== null && f.fact !== null && f.fact ? f.fact : false))
                .map((f) => f?.fact) || []) as string[],
        }}
        quote={entry.campaignPayoff || undefined}
        oberonFactsProps={{
            title: entry.campaignBenefitsHeader ? entry.campaignBenefitsHeader : undefined,
            facts: entry.campaignBenefits
                ?.filter((bf) =>
                    bf !== null && bf.header !== null && bf.header && bf.description !== null && bf.description
                        ? bf
                        : false
                )
                .map((bf) => ({ title: bf?.header, description: bf?.description?.content })) as Array<{
                title: string;
                description: string;
            }>,
        }}
        ctaForm={((entry.campaignCtaForm || []) as CTAFragment[])?.[0]}
    />
);

export default MarketingMicroservicesWhitepaper;
